module.exports = {
  name: 'Kishor Maharjan',
  shortName: 'Kishor',
  description: 'I am a freelance photographer based in Patan, Nepal. I focus on street and documentary photography and enjoy photographing people in their natural environment.',
  url: 'https://kishor.netlify.com', // Domain of your site without prefix!
  siteTitle: 'Kishor Maharjan', // <title>
  manifestName: 'Kishor Maharjan',
  manifestShortName: 'Kishor', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/icon.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  prefix: '/',
  author: 'Kishor Maharjan',
  heading: 'Kishor Maharjan',
  subHeading: 'Kishor Maharjan is a Kathmandu based photographer. His interest in music and design brought him to photography. In his works, he explores rural parts of the country and makes a documentary of stories that were otherwise unheard of. He uses photography as a medium to explore, question, document, travel, and to challenge himself.',

  // social
  socialLinks: [
    {
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://www.instagram.com/kameradai/',
    },
    {
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/mahkishor',
    },
    {
      icon: 'fa-facebook-f',
      name: 'Facebook',
      url: 'https://facebook.com/mahkishor',
    },
  ],
  email: 'mahkishor@gmail.com',
  phone: '9841-852871',
  address: 'Patan, Nepal',
};
